/**
 * Main application file
 */

import platform from 'platform';
import startup from 'startup.js';
import App from 'vue-components/App';

// Importing the `@rails/ujs` package and running `start()` ensures, among other things, that rails-ujs is able to
// properly handle `link_to` helpers utilizing the `method` option with a non-GET HTTP verb (`:delete`, `:post`, etc.)
//
// Example use case from our application which doesn't work without this import/start:
// `= link_to("Sign out", gs_logout_path, method: :delete)`
import Rails from '@rails/ujs';
Rails.start();

// Vendor files
import 'vendor/confetti-source';
import 'vendor/cookies.min.js';
import 'vendor/modernizr.min.js';
import 'vendor/svgxuse.min.js';
import 'jquery';
import 'jquery-ujs';
import 'jquery-validation';
import 'cookies-js';
import 'filesize';
import 'fontfaceobserver';
import 'platform';
import 'nodelist-foreach-polyfill';
import Vue from 'vue';

const html = document.querySelector('html');

if (platform.name) {
	html.classList.add(`browser-${platform.name.toLowerCase().replace(' ', '')}`);
}

if (platform.os.family === 'OS X') {
	html.classList.add('os-osx');
}

const vueApp = document.querySelector('vue-app');
if (vueApp) {
	new Vue({ ...App, el: 'vue-app' });
}

window.dataLayer = window.datalayer || [];

window.startup = startup();
